<script setup>
    // Import components
    import { OfferTypes } from '~/enum.js';
    import PrimevueProgressSpinner from 'primevue/progressspinner';

    const { $api, $me } = useNuxtApp();
    const me = $me.get();

    // Initialize i18n
    const { localeProperties, t: $t } = useI18n();
    const locale = computed(() => localeProperties.value.language);
    const localePath = useLocalePath();

    // Datas initialization
    const loading = ref(false);
    const expandedKeys = ref([]);
    const orderBy = ref(null);
    const showFilters = ref(false);
    const shaking = ref({});

    // List of order by options to be displayed
    const orderByOptions = [
        { label: $t('Created at'), value: 'created_at-asc' },
        { label: $t('End of the auction'), value: 'ending_bid_at-desc' },
        { label: $t('Units'), value: 'total_quantity-desc' },
    ];

    // Get current route, or marketplace default route
    // to load offers depending on the current route
    const route = useRoute().fullPath.split('?')[0];

    let url = `v1${route}`;
    url = url.endsWith('/') ? url.slice(0, -1) : url;

    // Get offers and filters before page laod
    const { data } = await $api.get(url, { params: { firstLoad: 1 }});
    if (!data.value) {
        throw createError({ statusCode: 404 })
    }

    const banner = ref(data.value.banner);
    const offers = ref(data.value.offers);
    const promos = ref(data.value.promo_cards);
    const filters = ref(data.value.filters.map(filter => {
        filter.originalDisabled = filter.disabled;
        filter.selectable = true;
        return filter;
    }));
    const selectedKeys = ref(data.value.defaultFilters || []);
    const noShowUrl = ref(data.value.lastNoshowInvoicePdfUrl);

    const page = ref(data.value.page);

    // Apply SEO title and meta
    if (page.value) {
        useHead({
            title: page.value.seo_title[locale.value],
            meta: [
                { name: 'description', content: page.value.seo_description[locale.value] }
            ],
        });
    }

    /**
     * Get elements to display in the breadcrumb
     * @returns {Array}
     */
    const breadcrumb = computed(() => {
        // Default marketplace
        let breadcrumb = [
            {label: $t('Job lot'), to: localePath('index')},
        ];

        // If the page is not default marketplace,
        // add it to the breadcrumb
        if (page.value.id > 1) {
            breadcrumb.push({
                label: page.value.breadcrumb_title[locale.value],
                to: localePath(page.value.slug[locale.value]),
            });
        }

        return breadcrumb;
    });

    /**
     * Get current page route translated for each locales
     * @returns {Object}
     */
    const paths = {};
    let config = useRuntimeConfig();
    let protocol = localeProperties.value.protocol + '://';

    for (let domain of config.public.domains) {
        let lang = domain.language;
        if (page.value.slug[lang]) {
            paths[domain.code] = protocol + domain.domain;
            if (useRoute().fullPath.startsWith('/p/')) {
                paths[domain.code] += page.value.slug[lang];
            }


            let iso = domain.language;

            if(domain.code === 'uk') {
              iso = 'en-gb';
            }

            // Add alternate link to head for SEO purpose
            useHead({
                link: [
                    {
                        rel: 'alternate',
                        href: paths[domain.code],
                        hreflang: iso
                    },
                ],
            });
        }
    }

    /**
     * Map filters to tree format
     * From rows with parentKey at the same level
     * To array with children in the parent
     * @returns {Array}
     */
    const tree = computed(() => {
        // Recursive function to get children from node
        const getChildren = (node) => {
            let children = filters.value.filter(child => child.parentKey === node.key && child.label);

            // If node has children, add them to the children array
            if (children.length) {
                children.map(child => getChildren(child));
                node.children = children;
            }

            return node;
        };

        // Get the parent filters and map them to recursively add their children
        return filters.value.filter(filter => ! filter.parentKey).map(filter => {
            let node = getChildren(filter);
            node.selectable = false;
            return node;
        });
    });

    // Expand the first node of the tree
    tree.value.forEach(node => {
        expandedKeys.value[node.key] = true;
    });

    /**
     * Group active filters ids by type
     * Ex: ['quality-A', 'quality-B'] => { quality: ['A', 'B'] }
     * @returns {Object}
     */
    const activeFilters = computed(() => {
        // Keep only checked filters
        let checked = Object.keys(selectedKeys.value).filter(key => selectedKeys.value[key].checked);

        // Group filters by type
        return checked.reduce((acc, key) => {
            let obj = key.split('-');
            let type = obj[0];

            // Init array for the group if empty
            acc[type] = acc[type] || [];

            // If not empty id, add it to the group
            if (obj[1]) {
                obj.shift();
                acc[type].push(obj.join('-'));
            }

            return acc;
        }, {});
    });

    const toggleFilters = (bool) => {
        showFilters.value = bool !== undefined ? bool : ! showFilters.value;
        document.body.style.overflow = showFilters.value ? 'hidden' : 'auto';
    };

    /**
     * Load offers and filters depending on current filters
     */
    const loadOffers = async () => {
        // Show loader
        loading.value = true;

        // Get offers and filters using user filters
        await $api.get(url, {
            params: {
                filters: activeFilters.value,
                order: orderBy.value,
            },
            onSuccess: (response) => {
                // Set offers
                offers.value = response.offers;

                // Update disabled state
                filters.value.forEach((filter) => {
                    // Get the key of the main parent
                    let masterKey = filter.masterKey || filter.parentKey || filter.key;

                    // If the main parent is checked, restore original disable state
                    if (selectedKeys.value[masterKey] !== undefined) {
                        filter.disabled = filter.originalDisabled;
                        return;
                    }

                    // Apply the response disable state
                    filter.disabled = response.filters.filter(f => f.key === filter.key && ! f.disabled).length === 0;

                    // Apply the response disable state
                    filter.disabled = response.filters.filter(f => f.key === filter.key && ! f.disabled).length === 0;
                });

                // Hide loader 100ms after the end of the request
                setTimeout(() => {
                    loading.value = false;
                }, 100);
            }
        });
    };

    const countFilters = computed(() => {
        return Object.keys(selectedKeys.value).filter(key => {
            return selectedKeys.value[key].checked
        }).reduce((current, key) => current + selectedKeys.value[key].count, 0);
    });

    const removeAllFilters = () => {
        selectedKeys.value = {};
        loadOffers();
    }

    const gtmFilterClick = (event) => {
        // GTM Click - Filter
        dataLayer.push({
            event: 'generic_event',
            event_name: 'click_filter',
            filter: event.label,
        });
    }

    const gtmProductSort = (event) => {
        // GTM Click - Product Listing - Sort
        dataLayer.push({
            event: 'engagement',
            event_name: 'sort',
            sort_type: event.value,
        });
    }

    const gtmPromotion = (slot, banner) => {
        // GTM Click - Promotion
        dataLayer.push({
            event: 'ecommerce',
            event_name: 'select_promotion',
            ecommerce: {
                creative_name: banner.img,
                creative_slot: slot,
                promotion_id: banner.url,
                promotion_name: banner.alt,
            }
        });
    }

    if (process.client) {
        initWebsocket();

        Echo.channel(`offers`)
            .listen('OrderBidCreatedEvent', (e) => {
                let index = Object.keys(offers.value).find(key => offers.value[key].id === e.offer.id);

                if (index && offers.value[index]) {
                    offers.value[index].count_bids = e.offer.count_bids;
                    offers.value[index].ending_bid_at = e.offer.ending_bid_at;
                    offers.value[index].last_bid_amount = e.offer.last_bid_amount;

                    // Update the offer's winner
                    if (e.buyer === me?.corporation?.id) {
                        offers.value[index].winning = true;
                    } else if (offers.value[index].winning) {
                        offers.value[index].winning = false;
                    }

                    // Shake the bid msg for 1s
                    shaking.value[e.offer.id] = true;
                    setTimeout(() => {
                        shaking.value[e.offer.id] = false;
                    }, 1000);
                }
            });
    }

    // Show schema.org informations only if user is not logged in (it is usefull only for search engines)
    if (! $me.check()) {
        const baseUrl = localeProperties.value.protocol + '://' + localeProperties.value.domain;
        // Schema.org for the company
        useHead({
            script: [
                {
                    type: 'application/ld+json',
                    innerHTML:
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Stocklear",
                        "url": baseUrl,
                        "logo": "https://api.stocklear.fr/images/logo.png",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "33 All. de la Raperie",
                            "postalCode": "59493",
                            "addressLocality": "Villeneuve d'Ascq, France"
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+33-339-800-034",
                            "contactType": "customer service"
                        },
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "5",
                            "bestRating": "5",
                            "worstRating": "1",
                            "reviewCount": "6"
                        },
                        "sameAs": [
                            "https://www.linkedin.com/company/boost-your-brand-world/"
                        ]
                    }
                }
            ],
        })

        // Schema.org for the offers
        for (let index in offers.value) {
            let offer = offers.value[index];
            useHead({
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: {
                            "@context": "https://schema.org",
                            "eventStatus": "https://schema.org/EventScheduled",
                            "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                            "@type": offer.type === OfferTypes.PURCHASE ? 'Ticket' : 'Event',
                            "startDate": offer.starting_bid_at,
                            "endDate": offer.ending_bid_at,
                            "name": offer.title,
                            "location": {
                                "@type": "VirtualLocation",
                                "url": baseUrl + localePath({name: 'marketplace-detail-id', params: {id: offer.id}})
                            },
                            "image": [
                                offer.illustration,
                            ],
                            "description": offer.description,
                            "organizer": {
                                "@type": "Organization",
                                "name": "Stocklear",
                                "url": baseUrl
                            }
                        }
                    }
                ],
            });
        }
    }

    // Watch scroll Y to toggle the "Display lots" button
    const scrollY = ref(0);

    // On mounted, add scroll event listener
    onMounted(() => {
        window.addEventListener("scroll", (e) => scrollY.value = e.target.documentElement.scrollTop);
    });

    // On unmounted, remove scroll event listener
    onUnmounted(() => {
        window.removeEventListener("scroll", (e) => scrollY.value = e.target.documentElement.scrollTop);
    });
</script>

<template>
    <NuxtLayout name="frontend" :enableHeadAlternate="false">
        <!-- Override lang switcher -->
        <template #langswitcher>
            <LangSwitcher :paths="paths" />
        </template>

        <!-- Override breadcrumb -->
        <template #breadcrumb>
            <PrimevueBreadcrumb :model="breadcrumb"/>
        </template>

        <!-- Login reminder -->
        <LayoutLoginReminder activationMode="onExit" />

        <!-- Show page description content -->
        <article class="marketplace__description" v-html="page.content_1[locale]" />

        <div v-if="banner" id="marketplace__banner__top">
            <NuxtLink :to="banner.url" @click="gtmPromotion('marketplace-top', banner)">
                <NuxtImg
                    :src="banner.img"
                    :alt="banner.alt"
                />
            </NuxtLink>
        </div>

        <section id="marketplace">
            <!-- Filter tree -->
            <div id="marketplace__filters" :class="{opened: showFilters}">
                <div id="marketplace__filters__close" class="visible-xl">
                    <PrimevueButton
                        :label="$t('Back to lots')"
                        icon="material-symbols:cancel"
                        iconPos="right"
                        @click="toggleFilters()"
                    />
                </div>

                <div v-if="countFilters" id="marketplace__filters__active">
                    <PrimevueTag>
                        <span>{{ $t('{number} active filters', {number: countFilters}) }}</span>
                        <Icon
                            name="material-symbols:close-rounded"
                            class="cursor-pointer"
                            @click="removeAllFilters()"
                        />
                    </PrimevueTag>
                </div>

                <PrimevueTree
                    v-if="filters.length"
                    v-model:selectionKeys="selectedKeys"
                    v-model:expandedKeys="expandedKeys"
                    :value="tree"
                    selectionMode="checkbox"
                    :paddingLeft="false"
                    @update:selectionKeys="loadOffers"
                    @node-select="gtmFilterClick"
                />
            </div>

            <!-- Button to show number of lots displayed when filtered, scroll top when clicked -->
            <ClientOnly v-if="countFilters && (scrollY > 750 || showFilters)">
                <div id="marketplace__filters__button">
                    <PrimevueButton
                        :label="$t('Display {number} lots', { number: offers.length })"
                        :class="{ 'w-full mx-5': showFilters }"
                        @click="scrollTo('app'); toggleFilters(false)"
                    />
                </div>
            </ClientOnly>

            <!-- Offers -->
            <div id="marketplace__offers">
                <!-- Offers loader -->
                <div v-if="loading" id="marketplace__offers__loader">
                    <PrimevueProgressSpinner />
                </div>

                <!-- Offers list grid -->
                <div v-else id="marketplace__offers__grid">
                    <!-- No show message -->
                    <div v-if="noShowUrl" id="marketplace__noshow">
                        <div id="marketplace__noshow__icon">
                            <Icon name="material-symbols:block" />
                        </div>

                        <div id="marketplace__noshow__message">
                            <h3>{{ $t('Your account is suspended.') }}</h3>
                            <p>{{ $t('You may not bid due to non-payment of one or more orders.') }}</p>
                            <p>{{ $t('To reactivate your account, please pay the service fee for these orders.') }}</p>

                            <NuxtLink :to="noShowUrl">
                                <span>{{ $t('Reactivate my account') }}</span>
                                <Icon name="material-symbols:arrow-right-alt-rounded" />
                            </NuxtLink>
                        </div>
                    </div>

                    <!-- Order grid by -->
                    <div id="marketplace__offers__grid__orderby">
                        <div>
                            <Dropdown
                                v-model="orderBy"
                                :options="orderByOptions"
                                optionLabel="label"
                                optionValue="value"
                                :placeholder="$t('Order by')"
                                @update:modelValue="gtmProductSort($event); loadOffers();"
                            />
                        </div>

                        <PrimevueButton
                            :label="$t('Filters')"
                            severity="secondary"
                            icon="material-symbols:filter-alt"
                            class="visible-xl"
                            text
                            @click="toggleFilters()"
                        />
                    </div>

                    <template v-if="offers.length">
                        <!-- Offers list -->
                        <MarketplaceOffers
                            :offers="offers"
                            :show-details="true"
                            :shaking="shaking"
                            :slug="page.slug[locale]"
                            :name="page.breadcrumb_title[locale]"
                        />

                        <MarketplacePromoCard
                            v-if="page.id === 1"
                            v-for="promo in promos"
                            :promo="promo"
                            :style="`order: ${promo.order * 2 - 1}`"
                        />
                    </template>

                    <!-- No offers message -->
                    <LazyMarketplaceStayInformed
                        v-else
                        :page="page"
                    />

                    <!-- Page description detail -->
                    <article v-if="page.content_2[locale]" class="marketplace__description grid-full-row mt-6" :style="`order: 999999`" v-html="page.content_2[locale]" />
                </div>
            </div>
        </section>
    </NuxtLayout>
</template>

<style lang="scss">
    @import '~/assets/scss/animations';

    #marketplace__offers__loader {
        @include flex-center;
        position: absolute;
        top: 0;
        inset-inline: 0;
        height: 25rem;
        z-index: 9;
    }

    .marketplace__description {
        @include flex-column;
        margin-bottom: 2rem;

        h1,
        h2,
        h3 {
            margin-bottom: .5rem;
            color: var(--text-color);

            * {
                background: transparent !important;
                color: inherit !important;
                font-size: inherit !important;
            }
        }

        h1 {
            @include text-2xl;
        }

        h2,
        h3 {
            @include text-lg;
            font-weight: 500;
        }

        p {
            color: var(--text-color-secondary);

            * {
                background: transparent !important;
                color: inherit !important;
            }

            & + h2,
            & + h3 {
                margin-top: 1.5rem;
            }
        }
    }

    #marketplace__noshow {
        display: flex;
        gap: 1.5rem;
        grid-column: 1/-1;
        padding: 1rem 1.5rem;
        border-radius: .25rem;
        background-color: var(--red-100);
        border: 1px solid var(--red-600);
        color: var(--red-600);

        #marketplace__noshow__icon {
            @include flex-center;

            .icon {
                font-size: 3rem;
            }
        }

        #marketplace__noshow__message {
            @include flex-column;

            h3 {
                @include text-2xl;
            }

            a {
                display: flex;
                align-items: center;
                gap: .25rem;
                margin-top: 1rem;
                color: inherit;
                font-weight: 600;

                & > :first-of-type {
                    text-decoration: underline;
                }
            }
        }
    }

    #marketplace__banner__top {
        display: flex;
        height: 9.125rem;
        margin-bottom: 2rem;
        border-radius: .5rem;
        overflow: hidden;

        & > a {
            display: flex;
            width: 100%;

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    #marketplace {
        display: flex;

        #marketplace__offers {
            @include vh('min-height', 100);
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            position: relative;
            height: fit-content;

            #marketplace__offers__grid {
                @include grid(3);
                height: fit-content;
                width: 100%;

                #marketplace__offers__grid__orderby {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    grid-column: 1/-1;
                }
            }
        }

        #marketplace__filters {
            max-width: var(--width-card);
            width: 100%;

            #marketplace__filters__close {
                position: sticky;
                top: 0;
                z-index: 2;
                margin-bottom: 1rem;

                button {
                    width: 100%;
                }
            }

            #marketplace__filters__active {
                display: flex;
                flex-wrap: wrap;
                gap: .25rem;
                margin-bottom: 1rem;

                & > .p-tag {
                    gap: .25rem;
                }
            }

            ul.p-tree-container > li > div > .p-treenode-label {
                font-weight: 600;
                text-transform: uppercase;
            }
        }

        #marketplace__filters__button {
            display: flex;
            justify-content: center;
            position: fixed;
            inset-inline: 0;
            bottom: 1rem;
            z-index: 12;
        }

        @media (max-width: $breakpoint-lg-max) {

            #marketplace__offers {
                #marketplace__offers__grid {
                    max-width: 100% !important;
                }
            }

            #marketplace__filters {
                position: fixed;
                inset: 0;
                max-width: 100%;
                padding: 1rem 2rem;
                padding-bottom: 5rem;
                background: white;
                z-index: 11;
                transform: translateX(-100%);
                transition: transform ease-in-out .3s;
                opacity: 0;
                overflow: auto;

                &.opened {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }

        @media (max-width: $breakpoint-sm-max) {

            #marketplace__offers #marketplace__offers__grid {
                @include grid(2);
            }
        }

        @media (max-width: $breakpoint-xs-max) {

            #marketplace__offers #marketplace__offers__grid {
                @include grid(1);
            }
        }
    }

    .operations-purchase {
        @extend .text-gradient;
        background-image: linear-gradient(120deg, #22c55b, #43bfa1, #a0eb25);
    }
</style>
