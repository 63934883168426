<script setup>
    const props = defineProps({
        promo: {
            type: Object,
            required: true,
        },
    })
</script>

<template>
    <Card
        class="offer promo__card"
        :url="props.promo.url"
    >
        <!-- Corporation illustration -->
        <template #header>
            <div class="offer__corporation px-2 text-center h-auto">
                <h2 class="text-base">{{ props.promo.title }}</h2>
            </div>

            <!-- Offer main illustration -->
            <div class="offer__img">
                <NuxtImg
                    v-if="props.promo.illustration"
                    :src="props.promo.illustration.fullUrl"
                    alt="Promo illustration"
                    loading="lazy"
                    class="h-full w-full"
                    style="object-fit: cover"
                />
            </div>
        </template>

        <!-- Offer footer with prices and button -->
        <template #footer>
            <div class="offer__footer">
                <!-- GoTo offer button -->
                <PrimevueButton
                    :label="props.promo.button_text || $t('View the offer')"
                    icon="material-symbols:arrow-right-alt-rounded"
                    severity="success"
                    iconPos="right"
                    class="w-full"
                />
            </div>
        </template>
    </Card>
</template>

<style lang="scss">
    .promo__card {

        .card__header {
            @include flex-column;
            height: 100%;

            .offer__img {
                flex-grow: 1;
            }
        }
    }
</style>
