<script setup>
    import PrimevueTree from 'primevue/tree';

    const emit = defineEmits(['update:selectionKeys']);
    const props = defineProps({
        value: {
            type: Array,
            default: () => [],
        },

        selectionKeys: {
            type: Object,
            default: () => {},
        },
    });

    const selectionKeys = reactive(props.selectionKeys);

    const propagateDown = (node, check) => {
        if (check) selectionKeys[node.key] = { checked: true, partialChecked: false, count: node.children ? 0 : 1 };
        else delete selectionKeys[node.key];

        if (node.children && node.children.length) {
            for (let child of node.children) {
                propagateDown(child, check);
            }
        }
    }

    const propagateUp = (node) => {
        if (! node.parentKey) return;

        let explodedKey = node.parentKey.split('-');
        let parent = props.value.find(n => n.key === explodedKey[0]);
        if (explodedKey[1]) {
            parent = parent.children.find(n => n.key === node.parentKey);
        }

        const children = parent.children.filter(child => !child.disabled);
        const selectedChildren = children.filter(child => selectionKeys[child.key]);

        if (selectedChildren.length === children.length) {
            selectionKeys[parent.key] = { checked: true, partialChecked: false };
        } else if (selectedChildren.length > 0) {
            selectionKeys[parent.key] = { checked: false, partialChecked: true };
        } else {
            delete selectionKeys[parent.key];
        }

        propagateUp(parent);
    }

    /**
     * Met à jour la sélection des nodes
     */
    const updateSelectionKeys = (node, check) => {
        if (node.disabled || ! node.parentKey) return;

        // Met à jour les enfants
        propagateDown(node, check);

        // Met à jour les parents
        propagateUp(node, check);

        emit('update:selectionKeys', { ...selectionKeys });
    }
</script>

<template>
    <PrimevueTree
        :value="props.value"
        :selectionKeys="selectionKeys"
        :pt="{
            label: { class: 'flex-grow-1' },
            toggler: { class: 'flex-order-3 h-auto' },
            container: { class: 'flex flex-column gap-5' },
            nodeicon: { class: 'hidden' },
            content: ({ props }) => {
                return {
                    class: props.node.class
                }
            },
            subgroup: ({ props }) => {
                return {
                    class: {
                        'p-0': true,
                        'flex flex-column gap-2 mt-3': props.level === 1,
                        'mt-2 pl-3': props.level === 3,
                        'p-treenode-disabled': props.node.disabled,
                    }
                }
            }
        }"
        @node-select="updateSelectionKeys($event, true)"
        @node-unselect="updateSelectionKeys($event, false)"
    >
        <!-- Icon du node -->
        <!-- <template #nodeicon="{ node }">
            <Icon
                v-if="node.icon"
                :name="node.icon"
            />
        </template> -->

        <!-- Node -->
        <template #default="{ node }">
            <template v-if="node.label?.name">
                <span>{{ node.label.name }}</span>

                <PrimevueTag
                    v-if="node.label.is_new"
                    severity="danger"
                    :value="$t('Is new')"
                />
            </template>

            <template v-else>
                <span>{{ node.label }}</span>
            </template>
        </template>

        <!-- Icon du toggler -->
        <template #togglericon="{ expanded }">
            <Icon
                :name="expanded ? 'carbon:caret-down' : 'carbon:caret-right'"
            />
        </template>
    </PrimevueTree>
</template>
